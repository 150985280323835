<template lang="html">
    <div>
        <b-navbar type="light" class="nav-bg" fixed="top">
            <router-link to="/profile" style="margin-left: 20px;font-size: 32px;">
                    <b-icon icon="arrow-left" font-scale="1"></b-icon>
            </router-link>
            <span> အောင်မြင်ပြီးသော အော်ဒါများ</span>
        </b-navbar>
        <b-container class="content-section" >
            <loading :active.sync="isLoading" :is-full-page="fullPage"></loading>
            <router-link class="btn btn-info text-center" to="/distri-order-history-list" style="height: 37px;line-height: 1.5;width: 30%;margin: 2%;">
                ဆိုင်အော်ဒါ
            </router-link>
            <router-link class="btn btn-info text-center" to="/factory-order-history-list" style="height: 37px;line-height: 1.5;width: 30%;margin: 1%;">
                စက်ရုံအော်ဒါ
            </router-link>
            <router-link class="btn btn-success text-center" to="/return-list" style="height: 37px;line-height: 1.5;width: 30%;margin: 1%;">
                ရေဘူးလက်ကျန်
            </router-link>
            <div class="card-scroll" >
                <table class="table table-cart bg-white" >
                    <tr v-for="(remain, index) in remains">
                        <td>{{remain.name}}</td>
                        <td style="width: 100px;">
                            {{remain.qty}}
                        </td>
                    </tr>
                </table>
            </div>
        </b-container>
    </div>
</template>

<script lang="js">
    import {
        mapState,
        mapActions
    } from 'vuex'
    import Loading from 'vue-loading-overlay'
    import 'vue-loading-overlay/dist/vue-loading.css';
    export default {
        name: 'item',
        components: {
            Loading
        },
        data() {
            return {
                isLoading: false,
                fullPage: true,
                status: 'pending',
                remains: []
            }
        },
        computed: {},
        methods: {
            ...mapActions({
                ReturnListAction: 'ReturnListAction',
            }),
            async completeInfo() {
                await this.ReturnListAction().then(res => {
                    if (res.status == "success") {
                        this.remains = res.data
                        this.isLoading = false
                    }
                }).catch(err => this.isLoading = true)
            },
            async submitReturn(id) {
                this.isLoading = true
                let option = {
                    status: this.status,
                    returnId: id
                }
                await this.statusChangeAction({
                    ...option
                }).then(res => {
                    this.isLoading = false
                    this.status = "pending"
                    this.completeInfo()
                }).catch(err => this.isLoading = true)
            }
        },
        async mounted() {
            this.completeInfo()
        }
    }
</script>

<style scoped lang="scss">
    td {
        padding: 5px;
    }
    // .float-filter {
    //     position: fixed;
    //     width: 60px;
    //     height: 60px;
    //     bottom: 90px;
    //     right: 2%;
    //     background-color: #318be3;
    //     color: #FFF;
    //     border-radius: 50px;
    //     text-align: center;
    //     box-shadow: 2px 2px 3px #999;
    // }

    .order-day {
        font-size: larger;
        font-weight: bold;
        font-style: italic;
    }

    .status-info {
        width: 100%;
        height: 100vh;
        background: #f8f9fa;
        z-index: 9999;
        position: absolute;
    }
    .card-scroll {
        margin-bottom: 100px;
        overflow: scroll;
    }
        /* Hide scrollbar for Chrome, Safari and Opera */
    .card-scroll::-webkit-scrollbar {
        display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .card-scroll {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    }
</style>